import axios, { AxiosError } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ExternalApiError, ValidationError } from 'types/ValidationError'
import { apiService, handleResponseData } from '../services/api.service'
import { IConsignmentDetail } from '../types/IConsignmentDetail'

interface IEoriSearchInput {
  form: IConsignmentDetail
  setForm: (form: IConsignmentDetail) => void
  // eslint-disable-next-line react/require-default-props
  isDisabled?: boolean
}
const nameMaxLength = 35

function EoriSearchInput(props: IEoriSearchInput) {
  const { form, setForm, isDisabled } = props
  const commonCompanyPrefixes = ['Sabiedrība ar ierobežotu atbildību ', 'UŽDAROJI AKCINĖ BENDROVĖ ', 'Usługi Transportowe ']
  const [eoriLoading, setEoriLoading] = useState(false)
  const { t } = useTranslation()

  const searchUsingEori = () => {
    setEoriLoading(true)
    if (form.eori === '' || !form.eori.match('[A-Za-z]{2}\\d{0,15}') || isDisabled === true) {
      setEoriLoading(false)
      return
    }
    if (form.eori.startsWith('GB')) {
      toast.error(t('eori.error', { context: 'GB' }))
      setEoriLoading(false)
      return
    }

    axios.get(
      apiService.getFullApiUrl('/eori'),
      {
        params: {
          eoriCode: form.eori.replace(' ', ''),
        },
      },
    ).then(handleResponseData).then((eoriConsignmentDetails: IConsignmentDetail) => {
      setEoriLoading(false)
      let companyName = eoriConsignmentDetails.name

      const containsTypicalPrefix = commonCompanyPrefixes.find((prefix) => companyName.toLowerCase().startsWith(prefix.toLowerCase()))

      if (containsTypicalPrefix !== undefined) {
        companyName = companyName.substring(containsTypicalPrefix.length).replaceAll('"', '')
      }

      if (companyName.length > nameMaxLength) {
        companyName = companyName.substr(0, nameMaxLength)
        toast.warning('Company name was too long and has been shortened. Please check and verify the name.')
      }
      setForm({
        ...form,
        city: eoriConsignmentDetails.city,
        country: eoriConsignmentDetails.country,
        name: companyName,
        street: eoriConsignmentDetails.street,
        zip: eoriConsignmentDetails.zip,
      } as IConsignmentDetail)
    }).catch((error: AxiosError<ValidationError | ExternalApiError>) => {
      let errorCode = ''
      if (error.response && 'errorCode' in error.response.data) {
        errorCode = error.response.data.errorCode ?? '400'
      }

      toast.error(t('eori.error', { context: errorCode }), { autoClose: 3000 })
      setEoriLoading(false)
    })
  }

  return (
    <form onSubmit={((event) => {
      if (!eoriLoading) {
        searchUsingEori()
      }
      event.preventDefault()
    })}
    >

      <div className="input-group">
        <input
          disabled={isDisabled}
          className="form-control"
          type="text"
          value={form.eori ? form.eori : ''}
          placeholder={t('declaration.eoriId')}
          onChange={(event) => {
            setForm({
              ...form,
              eori: event.target.value,
            })
          }}
        />
        <div className="input-group-append">
          <button
            disabled={isDisabled}
            type="button"
            className="btn btn-light h-100 border"
            onClick={(event) => {
              if (!eoriLoading) {
                searchUsingEori()
              }
              event.preventDefault()
            }}
          >
            {
              eoriLoading
              && <i className="fa fa-spinner-third fa-spin" />
            }
            {
              !eoriLoading
              && <i className="fal fa-search" />
            }
          </button>
        </div>
      </div>

    </form>
  )
}

export default EoriSearchInput
