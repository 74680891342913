import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useExpanded, usePagination, useTable } from 'react-table'
import { useSticky } from 'react-table-sticky'
import { CodelistResponse } from 'routes/phase5/common/models/codelist-response'
import { hasText } from 'routes/phase5/common/utils/common-util'
import { ISelectOption } from 'types/IClassifier'
import { UserPreference } from 'types/UserPreference'
import { TableFilter } from 'types/PageResponse'
import LoadingBackdrop from '../../../../components/LoadingBackdrop'
import { TablePagination } from '../../../Transits/Table/TablePagination'
import { NotificationContext } from '../../../../context/NotificationContext'
import getTableHeaders from './TableConfig'
import { getStatusOptions } from '../../../Transits/Table/config'
import useTransitOperationPage from '../hooks/useTransitOperationPage/api'
import { UserContext } from '../../../../context/UserContext'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import { dateFormatOptions } from '../../../../config/dateFormatterConfig'

interface TransitsTableParams {
  asideAction: (input: boolean, targetId: number) => void
  modalAction: (input: boolean, targetId: number) => void
  customsOffices: CodelistResponse[]
  departureCustomOffices: CodelistResponse[]
  startDate: number | Date | undefined
  endDate: number | Date | undefined
  tablePreferences: Array<UserPreference>
  commodityCodeFilter: string
  setCommodityCodeFilter: Dispatch<SetStateAction<string>>
  filterState: {
    filters: Array<TableFilter>
    setFilters: Dispatch<SetStateAction<Array<TableFilter>>>
  }
  statusFilterState: {
    statusFilter: string
    setStatusFilter: Dispatch<SetStateAction<string>>
  }
}

function TransitsTable(props: Readonly<TransitsTableParams>) {
  const {
    tablePreferences,
    modalAction,
    asideAction,
    customsOffices,
    departureCustomOffices,
    startDate,
    endDate,
    statusFilterState: {
      statusFilter,
      setStatusFilter,
    },
    filterState: {
      filters,
      setFilters,
    },
    commodityCodeFilter,
    setCommodityCodeFilter,
  } = props
  const { t, i18n } = useTranslation()
  const { user } = useContext(UserContext)
  const { contextHeaderNotificationMessage } = useContext(NotificationContext)

  const [sortBy, setSortBy] = useState<string>('id')
  const [orderBy, setOrderBy] = useState<string>('DESC')

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const toggleHeaderOrder = () => getTableHeaders(t, user?.role === 'ADMIN', tablePreferences)
  const columns = React.useMemo(() => toggleHeaderOrder(), [isMobile, i18n.language, tablePreferences])
  const [data, setData] = useState<Array<any>>([])
  const [totalPages, setTotalPages] = useState(0)
  const [loading, setLoading] = useState(true)

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
      },
      manualPagination: true,
      pageCount: totalPages,
    },
    useExpanded,
    useSticky,
    usePagination,
  )

  const {
    getAllTransitOperation,
    getAllTransitOperationAdmin,
  } = useTransitOperationPage({
    filters,
    isAdmin: user?.role === 'ADMIN',
    status: statusFilter,
    pageIndex,
    pageSize,
    startDate: new Intl.DateTimeFormat('et', dateFormatOptions).format(startDate),
    endDate: new Intl.DateTimeFormat('et', dateFormatOptions).format(endDate),
    sortBy,
    orderBy,
  })

  useEffect(() => {
    if (!hasText(commodityCodeFilter)) {
      setFilters([...filters.filter((filter) => filter.key !== 'commodityCode')])
    } else {
      setFilters([
        ...filters.filter((filter) => filter.key !== 'commodityCode'),
        { key: 'commodityCode', value: commodityCodeFilter },
      ])
    }
  }, [commodityCodeFilter])

  useEffect(() => {
    if (startDate && endDate) {
      setFilters([
        ...filters.filter((filter) => filter.key !== 'dateRangeStart' && filter.key !== 'dateRangeEnd'),
        { key: 'dateRangeStart', value: new Intl.DateTimeFormat('et', dateFormatOptions).format(startDate) },
        { key: 'dateRangeEnd', value: new Intl.DateTimeFormat('et', dateFormatOptions).format(endDate) },
      ])
    }
  }, [startDate, endDate])

  useEffect(() => {
    const searchStorage = localStorage.getItem('search')
    if (searchStorage !== null) {
      const search = JSON.parse(searchStorage) as TableFilter[]
      setFilters(search)
      const savedCommodityCodeFilter = search.find((filter) => filter.key === 'commodityCode')
      if (savedCommodityCodeFilter !== undefined) {
        setCommodityCodeFilter(savedCommodityCodeFilter?.value)
      }
    }
  }, [])

  useEffect(() => {
    if ((localStorage.getItem('search') !== null)) {
      const filterArray: Array<TableFilter> = []
      filters.forEach((filter) => {
        if (filter.key !== 'dateRangeStart' && filter.key !== 'dateRangeEnd') {
          filterArray.push(filter)
        }
      })
      localStorage.setItem('search', JSON.stringify(filterArray))
    } else {
      localStorage.setItem('search', JSON.stringify([]))
    }
  }, [filters])

  useEffect(() => {
    setData([])
    if (pageIndex === 0) {
      const localStorageSearch = JSON.parse(localStorage.getItem('search')!)
      const search = localStorageSearch.find((cachedFilter: TableFilter) => cachedFilter.key === 'statusFilter')
      const isStatusFilterMissingFromCache = localStorageSearch.find((filter: TableFilter) => filter.key === 'statusFilter') === undefined
      if (isStatusFilterMissingFromCache && hasText(statusFilter)) {
        localStorageSearch.push({ key: 'status', value: statusFilter })
        localStorage.setItem('search', JSON.stringify(localStorageSearch))
      } else if (!isStatusFilterMissingFromCache) {
        if (statusFilter !== '' && statusFilter !== search.value) {
          setStatusFilter(statusFilter)
        } else {
          setStatusFilter(search.value)
        }
        localStorageSearch[localStorageSearch.findIndex((filter: TableFilter) => filter.key === 'statusFilter')].value = statusFilter
        localStorage.setItem('search', JSON.stringify(localStorageSearch))
      }
      localStorageSearch.push({
        key: 'dateRangeStart',
        value: new Intl.DateTimeFormat('et', dateFormatOptions).format(startDate),
      })
      localStorageSearch.push({
        key: 'dateRangeEnd',
        value: new Intl.DateTimeFormat('et', dateFormatOptions).format(endDate),
      })
    }

    gotoPage(0)
  }, [filters, statusFilter, sortBy, orderBy])

  useEffect(() => {
    if (user?.role !== 'ADMIN'
      && getAllTransitOperation.data?.data !== undefined
      && getAllTransitOperation.isFetched && !getAllTransitOperation.isLoading) {
      setTotalPages(Number(getAllTransitOperation.data?.data?.totalPages))
      setData(getAllTransitOperation.data?.data?.content)
      if (!getAllTransitOperation.isLoading) setLoading(false)
    }
  }, [getAllTransitOperation, user?.role !== 'ADMIN'])

  useEffect(() => {
    if (user?.role === 'ADMIN') {
      if (getAllTransitOperationAdmin.data?.data !== undefined
        && getAllTransitOperationAdmin.isFetched && !getAllTransitOperationAdmin.isLoading) {
        setTotalPages(Number(getAllTransitOperationAdmin.data?.data?.totalPages))
        setData(getAllTransitOperationAdmin.data?.data?.content)
        if (!getAllTransitOperationAdmin.isLoading) setLoading(false)
      }
    }
  }, [getAllTransitOperationAdmin, user?.role === 'ADMIN'])

  const statusOptions: ISelectOption[] = getStatusOptions(t)

  return (
    <>
      <div
        className={`table__container table-responsive declaration-table__container ${isMobile ? 'not-in-mobile' : ''} ${contextHeaderNotificationMessage !== '' ? 'notification--open' : ''}`}
      >
        <table {...getTableProps()} className="table sticky table-hover fixed-columns mb-0">
          <TableHeader
            /* @ts-ignore */
            headerGroups={headerGroups}
            selectFilterOptions={statusOptions}
            selectFilterState={{
              selectFilter: statusFilter,
              setSelectFilter: setStatusFilter,
            }}
            sortState={{
              sortBy,
              setSortBy,
            }}
            filterState={{
              filters,
              setFilters,
            }}
            orderState={{
              orderBy,
              setOrderBy,
            }}
          />
          {!loading && (
            <TableBody
              isLoading={loading}
              rows={page}
              prepareRow={prepareRow}
              asideAction={asideAction}
              modalAction={modalAction}
              getTableBodyProps={getTableProps}
              customsOffices={customsOffices}
              departureCustomOffices={departureCustomOffices}
              refreshFilters={() => setFilters([...filters])}
            />
          )}
          <LoadingBackdrop loading={loading} />
        </table>
      </div>
      <TablePagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
    </>
  )
}

export default TransitsTable
