import {FieldPathRule, FormScope, RuleResult} from 'types/DeclarationP5'
import {ZodIssue} from 'zod'
import {DeclarationForm} from '../schemas/declarationFormSchema'
import {extractCountryFromDepartureOffice, removePrefix} from '../../../common/utils/classifier-util'
import {IS_TRANSITIONAL_PERIOD} from '../../../common/utils/ncts-constants'
import {excludeDeleted, hasText} from '../../../common/utils/common-util'
import C0001Validator from './C0001'
import C0015Validator from './C0015'
import C0030Validator from './C0030'
import C0035Validator from './C0035'
import C0055Validator from './C0055'
import C0060Validator from './C0060'
import C0191Validator from './C0191'
import C0343Validator from './C0343'
import C0387Validator from './C0387'
import C0403Validator from './C0403'
import C0502Validator from './C0502'
import C0505Validator from './C0505'
import C0531Validator from './C0531'
import C0542Validator from './C0542'
import C0586Validator from './C0586'
import C0598Validator from './C0598'
import C0599Validator from './C0599'
import C0670Validator from './C0670'
import C0806Validator from './C0806'
import C0821Validator from './C0821'
import C0822Validator from './C0822'
import C0823Validator from './C0823'
import C0826Validator from './C0826'
import C0837Validator from './C0837'
import C0839Validator from './C0839'
import R0020Validator from './R0020'
import C0250Validator from './C0250'
import R0506Validator from './R0506'
import R0507Validator from './R0507'
import R0601Validator from './R0601'
import R3061Validator from './R3061'
import R0905Validator from './R0905'
import R0789Validator from './R0789'
import R0855Validator from './R0855'
import R0994Validator from './R0994'
import B1820Validator from './transitional/B1820'
import B1823Validator from './transitional/B1823'
import B1896Validator from './transitional/B1896'
import B1877Validator from './transitional/B1877'
import B1892Validator from './transitional/B1892'
import E1103Validator from './E1103'
import R0220Validator from './R0220'
import B1897Validator from './transitional/B1897'
import C0394Validator from './C0394'
import C0382Validator from './C0382'
import NC9012Validator from './FI/NC9012'
import NC0035Validator from './FI/NC0035'
import C0671Validator from './C0671'
import C0710Validator from './C0710'
import NA61Validator from './EE/NA61'
import NA60Validator from './EE/NA60'
import NA59Validator from './EE/NA59'
import NA62Validator from './EE/NA62'
import R0909Validator from './R0909'
import R0911Validator from './R0911'
import { isTransportDocumentNumberUsable } from '../../tabs/general/PreviousDocuments'
import RP01Validator from './PL/RP01'
import { AmountUnit } from '../schemas/consignmentItemSchema';

function coalesceByRuleSeverity(rules: RuleResult[]): RuleResult {
  if (rules.some((rule) => rule === 'NOT_ALLOWED')) {
    return 'NOT_ALLOWED'
  }
  if (rules.some((rule) => rule === 'REQUIRED')) {
    return 'REQUIRED'
  }

  return 'OPTIONAL'
}

const invokeValidators = (formData: DeclarationForm) => {
  const updatedRules: FieldPathRule[] = []
  const updatePathResult = (path: FormScope, ruleResult: RuleResult | null) => {
    const ruleAtPath = updatedRules.find((item) => item.path === path)
    if (ruleAtPath) {
      ruleAtPath.ruleResult = ruleResult
    } else {
      updatedRules.push(
        {
          ruleResult,
          path,
          issues: [],
        },
      )
    }
  }
  const addPathIssue = (path: FormScope, issue: ZodIssue) => {
    const ruleAtPath = updatedRules.find((item) => item.path === path)
    if (ruleAtPath) {
      ruleAtPath.issues.push(issue)
    } else {
      updatedRules.push(
        {
          ruleResult: null,
          path,
          issues: [issue],
        },
      )
    }
  }

  const country = extractCountryFromDepartureOffice(formData.departureCustomsOffice)

  // Required fields
  formData.houseConsignment.forEach((houseConsignment, houseIndex) => {
    for (let i = 0; i < houseConsignment.consignmentItem.length; i += 1) {
      updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityDescriptionOfGoods`, 'REQUIRED')

      const {
        commodityHarmonizedSystemSubHeadingCode,
        commodityCombinedNomenclatureCode,
        previousDocument,
        supportingDocument,
        transportDocument,
      } = houseConsignment.consignmentItem[i]

      if (country === 'BG') {
        const notAllowedBulgarianCodes = [
          '870210', '870220', '870230', '870240', '870290', '870421', '870422', '870423', '870431', '870432',
          '870441', '870442', '870443', '870451', '870452', '870460', '870490', '871610', '871631', '871639', '871640',
          '871620', '840734', '840790', '840820',
        ]

        if (notAllowedBulgarianCodes.includes(commodityHarmonizedSystemSubHeadingCode)) {
          addPathIssue(`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityHarmonizedSystemSubHeadingCode`, {
            code: 'custom',
            path: [`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityHarmonizedSystemSubHeadingCode`],
            fatal: true,
            message: 'Contains Forbidden Codes',
          })
        }

        if (hasText(commodityCombinedNomenclatureCode)) {
          addPathIssue(`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityCombinedNomenclatureCode`, {
            code: 'custom',
            path: [`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityCombinedNomenclatureCode`],
            fatal: true,
            message: 'Maximum HS-Code length can only be 6 characters',
          })
        }

        if (commodityHarmonizedSystemSubHeadingCode.length > 6) {
          addPathIssue(`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityHarmonizedSystemSubHeadingCode`, {
            code: 'custom',
            path: [`houseConsignment.${houseIndex}.consignmentItem.${i}.commodityHarmonizedSystemSubHeadingCode`],
            fatal: true,
            message: 'Maximum HS-Code length can only be 6 characters',
          })
        }
      }

      previousDocument.forEach((doc, docIndex) => {
        if ((hasText(doc.procedureType)) && country === 'EE') {
          return
        }
        updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${i}.previousDocument.${docIndex}.documentType`, 'REQUIRED')
        if (isTransportDocumentNumberUsable(doc.documentType, country ?? '')) {
          updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${i}.previousDocument.${docIndex}.transportDocumentReferenceNumber`, 'REQUIRED')
        }

        updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${i}.previousDocument.${docIndex}.referenceNumber`, 'REQUIRED')
      })
      supportingDocument.forEach((doc, docIndex) => {
        updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${i}.supportingDocument.${docIndex}.documentType`, 'REQUIRED')
      })
      transportDocument.forEach((doc, docIndex) => {
        updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${i}.transportDocument.${docIndex}.documentType`, 'REQUIRED')
      })
    }
  })
  updatePathResult('security', 'REQUIRED')

  // After Transition Date
  // ToDo: Check with UK and / or other countries
  if (IS_TRANSITIONAL_PERIOD) {
    updatePathResult('departureCustomsOffice', 'REQUIRED')
    updatePathResult('placeOfLoading', 'REQUIRED')
    formData.activeBorderTransportMeans.filter(excludeDeleted).forEach((item, index) => {
      updatePathResult(`activeBorderTransportMeans.${index}.identificationNumber`, 'REQUIRED')
      updatePathResult(`activeBorderTransportMeans.${index}.customsOfficeAtBorderReferenceNumber`, 'REQUIRED')
      updatePathResult(`activeBorderTransportMeans.${index}.nationality`, 'REQUIRED')
      updatePathResult(`activeBorderTransportMeans.${index}.typeOfIdentification`, 'REQUIRED')
    })
    formData.departureTransportMeans.filter(excludeDeleted).forEach((item, index) => {
      updatePathResult(`departureTransportMeans.${index}.identificationNumber`, 'REQUIRED')
      updatePathResult(`departureTransportMeans.${index}.nationality`, 'REQUIRED')
      updatePathResult(`departureTransportMeans.${index}.typeOfIdentification`, 'REQUIRED')
    })

    formData.houseConsignment.filter(excludeDeleted).forEach((houseConsignment, houseIndex) => {
      houseConsignment.consignmentItem.filter(excludeDeleted).forEach((houseConsignmentItem, index) => {
        updatePathResult(`houseConsignment.${houseIndex}.consignmentItem.${index}.goodsMeasureGrossMass`, 'REQUIRED')
      })
    })
  }

  if (formData.grossMass === null || formData.grossMass <= 0) {
    addPathIssue('grossMass', {
      code: 'custom', path: ['grossMass'], fatal: true, message: 'Required',
    })
  }

  updatePathResult('grossMass', 'REQUIRED')

  if (formData.departureCustomsOffice === null || formData.departureCustomsOffice === '') {
    addPathIssue('departureCustomsOffice', {
      code: 'custom', path: ['departureCustomsOffice'], fatal: true, message: 'Required',
    })
  }
  updatePathResult('departureCustomsOffice', 'REQUIRED')

  if (formData.destinationCustomsOffice === null || formData.destinationCustomsOffice === '') {
    addPathIssue('destinationCustomsOffice', {
      code: 'custom', path: ['destinationCustomsOffice'], fatal: true, message: 'Required',
    })
  }
  updatePathResult('destinationCustomsOffice', 'REQUIRED')
  updatePathResult('security', 'REQUIRED')
  updatePathResult('guarantees', 'REQUIRED')
  updatePathResult('guarantees.0.profileName', 'REQUIRED')
  updatePathResult('guarantees.0.amount', 'REQUIRED')
  formData.houseConsignment.filter(excludeDeleted).forEach((house, index) => {
    updatePathResult(`houseConsignment.${index}.grossMass`, 'REQUIRED')
    if (house.grossMass === null || house.grossMass <= 0) {
      addPathIssue(`houseConsignment.${index}.grossMass`, {
        code: 'custom', path: [`houseConsignment.${index}.grossMass`], fatal: true, message: 'Required',
      })
    }
  })

  const consignmentConsigneeRequirement = C0001Validator.validateConsignmentConsigneeRequirement(formData)
  if (consignmentConsigneeRequirement) {
    updatePathResult('consignee', consignmentConsigneeRequirement)
  }

  const previousDocumentRequired = C0035Validator.isPreviousDocumentRequired(formData)
  if (previousDocumentRequired) {
    updatePathResult('previousDocument', previousDocumentRequired)
  }

  const containerIndicatorRequirement = C0822Validator.validateContainerIndicatorRequirement(formData)
  updatePathResult('containerIndicator', containerIndicatorRequirement)
  const transportEquipmentRequirement = C0823Validator.validateTransportEquipmentRequirement(formData)
  updatePathResult('transportEquipment', transportEquipmentRequirement)
  const limitDateRequirement = C0839Validator.validateLimitDateRequirement(formData)
  updatePathResult('limitDate', limitDateRequirement)

  const goodsReferenceRequirement = C0670Validator.validateGoodsReferenceRequirement(formData)
  formData.transportEquipment.forEach((item, transportEquipmentIndex) => {
    if (item.deleted) {
      return
    }
    const containerIdentificationNumberRequired = C0055Validator.isContainerIdentificationNumberRequired(formData, item.sequenceNumber)
    updatePathResult(`transportEquipment.${transportEquipmentIndex}.containerIdentificationNumber`, containerIdentificationNumberRequired)
    updatePathResult(`transportEquipment.${transportEquipmentIndex}.goodsReferences`, goodsReferenceRequirement)
    item.goodsReferences.forEach((goodsReferences, goodsReferenceIndex) => {
      updatePathResult(
        `transportEquipment.${transportEquipmentIndex}.goodsReferences.${goodsReferenceIndex}.declarationGoodsItemNumber`,
        'REQUIRED',
      )
    })
    item.seals.forEach((seal, sealIndex) => {
      updatePathResult(
        `transportEquipment.${transportEquipmentIndex}.seals.${sealIndex}.identifier`,
        'REQUIRED',
      )
    })
  })

  if (country === 'FI' && !hasText(formData.referenceNumberUCR)) {
    updatePathResult('referenceNumberUCR', 'REQUIRED')
  }
  const unloadingRequirement = C0191Validator.validatePlaceOfUnloadingRequirement(formData.security)
  updatePathResult('placeOfUnloading', unloadingRequirement)
  const transportAtBorderRequirement = C0599Validator
    .validateModeOfTransportAtBorderRequirement(formData.security, formData.departureCustomsOffice)
  updatePathResult('borderModeOfTransport', transportAtBorderRequirement)

  if (unloadingRequirement !== 'NOT_ALLOWED') {
    const unloadingCountryAndLocationRequirement = C0387Validator.validateCountryAndLocationRequirement(formData.placeOfUnloading.unLocode)
    updatePathResult('placeOfUnloading.location', coalesceByRuleSeverity([unloadingCountryAndLocationRequirement]))
    updatePathResult('placeOfUnloading.country', coalesceByRuleSeverity([unloadingCountryAndLocationRequirement]))
    if (country === 'FI') {
      updatePathResult('placeOfUnloading.unLocode', 'NOT_ALLOWED')
    }
  }

  const placeOfLoadingRequirement = C0403Validator.validatePlaceOfLoadingRequirement(formData)
  updatePathResult('placeOfLoading', placeOfLoadingRequirement)

  if (placeOfLoadingRequirement !== 'NOT_ALLOWED') {
    const loadingCountryAndLocationRequirement = C0387Validator.validateCountryAndLocationRequirement(formData.placeOfLoading.unLocode)
    updatePathResult('placeOfLoading.location', coalesceByRuleSeverity([loadingCountryAndLocationRequirement]))
    updatePathResult('placeOfLoading.country', coalesceByRuleSeverity([loadingCountryAndLocationRequirement]))
    if (country === 'FI') {
      updatePathResult('placeOfLoading.unLocode', 'NOT_ALLOWED')
    }
  }

  updatePathResult('locationOfGoods', C0710Validator.validateLocationOfGoodsRequirement(formData))

  updatePathResult('locationOfGoods.qualifierOfIdentification', 'NOT_ALLOWED')
  updatePathResult('locationOfGoods.additionalIdentifier', 'NOT_ALLOWED')
  if (hasText(formData.locationOfGoods?.typeOfLocation)) {
    updatePathResult('locationOfGoods.typeOfLocation', 'REQUIRED')
    updatePathResult('locationOfGoods', 'REQUIRED')
  }

  if (country !== 'FI') {
    if (hasText(formData.locationOfGoods?.typeOfLocation)) {
      updatePathResult('locationOfGoods.qualifierOfIdentification', 'REQUIRED')
      const unLocodeRequirement = C0394Validator.validateUnLocodeRequirement(formData)
      updatePathResult('locationOfGoods.unLocode', unLocodeRequirement)
    }

    updatePathResult('locationOfGoods.additionalIdentifier', C0671Validator.validateAdditionalIdentifierRequirement(formData))
  }

  const customsOfficeRequirement = C0394Validator.validateCustomsOfficeRequirement(formData)
  updatePathResult('locationOfGoods.referenceNumber', customsOfficeRequirement)

  const economicOperatorRequirement = country === 'FI'
    ? NC0035Validator.validateEconomicOperatorRequirement(formData) : C0394Validator.validateEconomicOperatorRequirement(formData)
  updatePathResult('locationOfGoods.economicOperatorIdentificationNumber', economicOperatorRequirement)

  const authorisationNumberRequirement = C0394Validator.validateAuthorisationNumberRequirement(formData)

  updatePathResult('locationOfGoods.authorisationNumber', authorisationNumberRequirement)
  const postCodeAddressRequirement = C0394Validator.validatePostCodeAddressRequirement(formData)
  updatePathResult('locationOfGoods.postcodeAddress', postCodeAddressRequirement)

  if (postCodeAddressRequirement !== 'NOT_ALLOWED') {
    const houseNumberRequirement = C0382Validator.validateHouseNumberRequirement(formData)
    updatePathResult('locationOfGoods.postcodeAddress.houseNumber', houseNumberRequirement)
  }

  const gnssRequirement = C0394Validator.validateGnssRequirement(formData)
  updatePathResult('locationOfGoods.gnss', gnssRequirement)
  const contactPersonRequirement = C0394Validator.validateContactPersonRequirement(formData)
  updatePathResult('locationOfGoods.contactPerson', contactPersonRequirement)
  const locationAddressRequirement = NC9012Validator.validateAddressRequirement(formData)
  updatePathResult('locationOfGoods.address', locationAddressRequirement)

  if (formData.locationOfGoods?.address !== null && locationAddressRequirement !== 'NOT_ALLOWED') {
    const locationOfGoodsPostcodeRequirement = C0505Validator.validatePostcodeRequirement(formData.locationOfGoods?.address?.country)
    updatePathResult('locationOfGoods.address.country', locationAddressRequirement)
    updatePathResult('locationOfGoods.address.streetAndNumber', locationAddressRequirement)
    updatePathResult('locationOfGoods.address.city', locationAddressRequirement)

    updatePathResult('locationOfGoods.address.postcode', locationOfGoodsPostcodeRequirement)
  }

  const hasUniqueItemConsignees = IS_TRANSITIONAL_PERIOD ? B1877Validator.hasMultipleUniqueConsigneeForConsignmentItem(formData) : false
  const consigneeRequirement = B1823Validator.validateConsignmentConsigneeRequirement(formData)
  updatePathResult('consignee', consigneeRequirement)
  updatePathResult('consignee.address', consigneeRequirement)
  if (consigneeRequirement !== 'NOT_ALLOWED' && !hasUniqueItemConsignees) {
    const consigneeAddressRequirement: RuleResult = 'REQUIRED' // C0250Validator.validateNameAndAddressRequirement(formData.consignee.identificationNumber)
    // ToDo: Check with UK and / or other countries
    // updatePathResult('consignee.identificationNumber', consigneeAddressRequirement)
    updatePathResult('consignee.name', consigneeAddressRequirement)
    updatePathResult('consignee.address', consigneeAddressRequirement)
    // @ts-ignore
    if (consigneeAddressRequirement !== 'NOT_ALLOWED') {
      updatePathResult('consignee.address.country', consigneeAddressRequirement)
      updatePathResult('consignee.address.streetAndNumber', consigneeAddressRequirement)
      updatePathResult('consignee.address.city', consigneeAddressRequirement)
      const consigneePostcodeRequirement = C0505Validator.validatePostcodeRequirement(formData.consignee.address?.country)
      updatePathResult('consignee.address.postcode', consigneePostcodeRequirement)
    }
  }
  updatePathResult('carrier.carrierName', 'REQUIRED')

  const consignorAddressRequirement: RuleResult = 'REQUIRED'// C0250Validator.validateNameAndAddressRequirement(formData.consignor.identificationNumber)
  // ToDo: Check with UK and / or other countries
  // updatePathResult('consignor.identificationNumber', consignorAddressRequirement)
  updatePathResult('consignor.name', consignorAddressRequirement)
  updatePathResult('consignor.address', consignorAddressRequirement)
  // @ts-ignore
  if (consignorAddressRequirement !== 'NOT_ALLOWED') {
    updatePathResult('consignor.address.country', consignorAddressRequirement)
    updatePathResult('consignor.address.streetAndNumber', consignorAddressRequirement)
    updatePathResult('consignor.address.city', consignorAddressRequirement)
    const consignorPostcodeRequirement = C0505Validator.validatePostcodeRequirement(formData.consignor.address?.country)
    updatePathResult('consignor.address.postcode', consignorPostcodeRequirement)
  }

  const borderConveyanceNumberRequirement = C0531Validator.validateBorderTransportMeansConveyanceReferenceNumberRequirement(formData)
  const borderTransportMeansRequirement = C0806Validator.validateBorderTransportMeansRequirement(formData)
  updatePathResult('activeBorderTransportMeans', borderTransportMeansRequirement)
  if (borderTransportMeansRequirement !== 'NOT_ALLOWED') {
    formData.activeBorderTransportMeans.forEach((item, index) => {
      if (item.deleted) {
        return
      }
      updatePathResult(`activeBorderTransportMeans.${index}.conveyanceReferenceNumber`, borderConveyanceNumberRequirement)
    })
  }

  formData.transitCustomsOffices.forEach((item, index) => {
    if (item.deleted) {
      return
    }
    const transitOfficeDateTimeRequirement = C0598Validator
      .validateTransitOfficeDateTimeRequirement(formData.departureCustomsOffice, formData.security, item)

    updatePathResult(`transitCustomsOffices.${index}.arrivalDateAndTime`, transitOfficeDateTimeRequirement)
  })

  const countryOfRoutingRequirement = C0586Validator.validateCountryOfRoutingRequirement(formData)
  updatePathResult('countryOfRoutingOfConsignment', countryOfRoutingRequirement)
  formData.countryOfRoutingOfConsignment.forEach((item, index) => {
    if (item.deleted) {
      return
    }
    updatePathResult(`countryOfRoutingOfConsignment.${index}.country`, 'REQUIRED')
  })
  const departureMeansForConsignmentRequirement = C0826Validator.validateDepartureTransportMeansForConsignmentRequirement(formData)
  updatePathResult('departureTransportMeans', departureMeansForConsignmentRequirement)

  if (IS_TRANSITIONAL_PERIOD) {
    if (departureMeansForConsignmentRequirement !== 'NOT_ALLOWED') {
      const identifyRequirement = B1892Validator.validateIdentificationRequirement(formData.containerIndicator)
      formData.departureTransportMeans.forEach((item, index) => {
        if (item.deleted) {
          return
        }
        updatePathResult(`departureTransportMeans.${index}.typeOfIdentification`, identifyRequirement)
        updatePathResult(`departureTransportMeans.${index}.identificationNumber`, identifyRequirement)
        updatePathResult(
          `departureTransportMeans.${index}.nationality`,
          B1897Validator.validateDepartureTransportMeansNationalityRequirement(formData),
        )
      })
    }
  }

  const hasValidDestination = R0905Validator.hasNonSmOrAdDestinationForCtcCountry(formData)
  if (!hasValidDestination) {
    addPathIssue('destinationCustomsOffice', {
      code: 'custom', path: ['destinationCustomsOffice'], fatal: true, message: 'R0905',
    })
  }
  const minimumOverallGrossMass = R0994Validator.getMinimumConsignmentGrossMass(formData.houseConsignment)
  if (formData.grossMass < minimumOverallGrossMass) {
    addPathIssue('grossMass', {
      code: 'custom', path: ['grossMass'], fatal: true, message: 'R0994',
    })
  }

  const activeBorderTransportMeansMultiplicity = R0789Validator.getActiveBorderTransportMeansMultiplicity(formData.transitCustomsOffices)
  if (activeBorderTransportMeansMultiplicity < formData.activeBorderTransportMeans.filter(excludeDeleted).length) {
    addPathIssue('activeBorderTransportMeans', {
      code: 'custom', path: ['activeBorderTransportMeans'], fatal: true, message: 'R0789',
    })
  }

  const identificationNumberLength = E1103Validator.getIdentificationNumberMaxLength()

  if (formData.activeBorderTransportMeans.length !== 0) {
    formData.activeBorderTransportMeans.forEach((item, index) => {
      if (item.deleted) {
        return
      }

      if (item.identificationNumber?.length > identificationNumberLength) {
        addPathIssue(`activeBorderTransportMeans.${index}.identificationNumber`, {
          code: 'custom', path: [`activeBorderTransportMeans.${index}.identificationNumber`], fatal: true, message: 'E1103',
        })
      }
    })
  }

  if (formData.departureTransportMeans.length !== 0) {
    formData.departureTransportMeans.forEach((item, index) => {
      if (item.deleted) {
        return
      }

      if (item.identificationNumber?.length > identificationNumberLength) {
        addPathIssue(`departureTransportMeans.${index}.identificationNumber`, {
          code: 'custom', path: [`departureTransportMeans.${index}.identificationNumber`], fatal: true, message: 'E1103',
        })
      }
    })
  }

  const departureTransportMeansMultiplicity = R0855Validator.getDepartureTransportMeansMultiplicity(formData.inlandModeOfTransport)
  if (departureTransportMeansMultiplicity < formData.departureTransportMeans.filter(excludeDeleted).length) {
    addPathIssue('departureTransportMeans', {
      code: 'custom', path: ['departureTransportMeans'], fatal: true, message: 'R0855',
    })
  }

  // Rules that should run once for all house consignments:
  const countryOfDestinationRequirement = C0343Validator.validateCountryOfDestinationRequirement(formData.countryOfDestination)
  const nomenclatureCodeRequirement = C0821Validator.validateCombinedNomenclatureCodeRequirement(formData)
  const houseConsignmentUcrRequirement = C0502Validator.validateHouseConsignmentUcrRequirement(formData)
  const isHouseUCREnabled = (houseConsignmentUcrRequirement !== 'NOT_ALLOWED')
  const hasUniqueHouseUCRs = isHouseUCREnabled ? R0506Validator.hasMultipleUniqueReferenceNumberUcrForHouseConsignment(formData) : true

  const departureMeansForHouseRequirement = C0826Validator.validateDepartureTransportMeansForHouseConsignmentRequirement(formData)
  const isHouseDepartureMeansEnabled = (departureMeansForHouseRequirement !== 'NOT_ALLOWED')

  const hasUniqueHouseDepartureMeans = isHouseDepartureMeansEnabled
    ? R0506Validator.hasMultipleUniqueDepartureTransportMeansForHouseConsignment(formData) : true
  const houseConsignmentConsigneeRequirement = C0001Validator.validateHouseConsignmentConsigneeRequirement(formData)
  const isHouseConsigneeEnabled = (houseConsignmentConsigneeRequirement !== 'NOT_ALLOWED')
  const hasUniqueHouseConsignees = isHouseConsigneeEnabled
    ? R0506Validator.hasMultipleUniqueConsigneeForHouseConsignment(formData) : true

  const houseConsignmentConsignorRequirement = C0542Validator.validateHouseConsignmentConsignorRequirement(formData)
  const isHouseConsignorEnabled = (houseConsignmentConsignorRequirement !== 'NOT_ALLOWED')
  const hasUniqueHouseConsignors = isHouseConsignorEnabled
    ? R0506Validator.hasMultipleUniqueConsignorForHouseConsignment(formData) : true

  formData.houseConsignment.forEach((houseConsignment, houseIndex) => {
    const houseScope: FormScope = `houseConsignment.${houseIndex}` as const

    if (isHouseUCREnabled && !hasUniqueHouseUCRs) {
      addPathIssue(`${houseScope}.referenceNumberUCR`, {
        code: 'custom', path: `${houseScope}.referenceNumberUCR`.split('.'), fatal: true, message: 'R0506',
      })
    }
    if (isHouseConsigneeEnabled && !hasUniqueHouseConsignees) {
      addPathIssue(`${houseScope}.consignee`, {
        code: 'custom', path: `${houseScope}.consignee`.split('.'), fatal: true, message: 'R0506',
      })
    }
    if (isHouseConsignorEnabled && !hasUniqueHouseConsignors) {
      addPathIssue(`${houseScope}.consignor`, {
        code: 'custom', path: `${houseScope}.consignor`.split('.'), fatal: true, message: 'R0506',
      })
    }
    if (isHouseDepartureMeansEnabled && !hasUniqueHouseDepartureMeans) {
      addPathIssue(`${houseScope}.departureTransportMeans`, {
        code: 'custom', path: `${houseScope}.departureTransportMeans`.split('.'), fatal: true, message: 'R0506',
      })
    }

    updatePathResult(`${houseScope}.referenceNumberUCR`, houseConsignmentUcrRequirement)
    updatePathResult(`${houseScope}.consignee`, houseConsignmentConsigneeRequirement)
    updatePathResult(`${houseScope}.consignor`, houseConsignmentConsignorRequirement)
    updatePathResult(`${houseScope}.departureTransportMeans`, departureMeansForHouseRequirement)

    if (houseConsignmentConsigneeRequirement !== 'NOT_ALLOWED') {
      const houseConsigneeDetailsRequirement = C0250Validator
        .validateNameAndAddressRequirement(houseConsignment.consignee?.identificationNumber)

      updatePathResult(`${houseScope}.consignee.name`, houseConsigneeDetailsRequirement)
      updatePathResult(`${houseScope}.consignee.address`, houseConsigneeDetailsRequirement)
      if (houseConsigneeDetailsRequirement !== 'NOT_ALLOWED') {
        updatePathResult(`${houseScope}.consignee.address.country`, houseConsigneeDetailsRequirement)
        updatePathResult(`${houseScope}.consignee.address.streetAndNumber`, houseConsigneeDetailsRequirement)
        updatePathResult(`${houseScope}.consignee.address.city`, houseConsigneeDetailsRequirement)
        const houseConsigneePostcodeRequirement = C0505Validator.validatePostcodeRequirement(houseConsignment.consignee?.address?.country)
        updatePathResult(`${houseScope}.consignee.address.postcode`, houseConsigneePostcodeRequirement)
      }
    }
    if (houseConsignmentConsignorRequirement !== 'NOT_ALLOWED') {
      const houseConsignorDetailsRequirement = C0250Validator
        .validateNameAndAddressRequirement(houseConsignment.consignor?.identificationNumber)

      updatePathResult(`${houseScope}.consignor.name`, houseConsignorDetailsRequirement)
      updatePathResult(`${houseScope}.consignor.address`, houseConsignorDetailsRequirement)
      if (houseConsignorDetailsRequirement !== 'NOT_ALLOWED') {
        updatePathResult(`${houseScope}.consignor.address.country`, houseConsignorDetailsRequirement)
        updatePathResult(`${houseScope}.consignor.address.streetAndNumber`, houseConsignorDetailsRequirement)
        updatePathResult(`${houseScope}.consignor.address.city`, houseConsignorDetailsRequirement)
        const houseConsignorPostcodeRequirement = C0505Validator.validatePostcodeRequirement(houseConsignment.consignor?.address?.country)
        updatePathResult(`${houseScope}.consignor.address.postcode`, houseConsignorPostcodeRequirement)
      }
    }
    const goodsItemsNetMassRequirement = C0837Validator.validateNetMassRequirement(houseConsignment, formData.reducedDatasetIndicator)

    // All house array types
    const {
      consignmentItem,
    } = houseConsignment

    const isItemDestinationEnabled = (countryOfDestinationRequirement !== 'NOT_ALLOWED')
    const hasUniqueItemDestinationCountries = isItemDestinationEnabled
      ? R0507Validator.hasMultipleUniqueCountryOfDestinationForConsignmentItem(houseConsignment) : true
    const consignmentItemUcrRequirement = C0502Validator.validateConsignmentItemUcrRequirement(formData, houseConsignment)
    const isItemUcrEnabled = (consignmentItemUcrRequirement !== 'NOT_ALLOWED')
    const hasUniqueItemReferenceUcr = isItemUcrEnabled
      ? R0507Validator.hasMultipleUniqueReferenceNumberUcrForConsignmentItem(houseConsignment) : true

    consignmentItem.forEach((item, consignmentItemIndex) => {
      if (item.deleted) {
        return
      }
      const itemScope: FormScope = `${houseScope}.consignmentItem.${consignmentItemIndex}` as const

      if (country === 'EE') {
        if (!NA59Validator.hasConsignmentItemPreviousDocumentsWithTypes(item.previousDocument)) {
          addPathIssue(`${itemScope}.previousDocument`, {
            code: 'custom', path: `${itemScope}.previousDocument`.split('.'), fatal: true, message: 'NA59',
          })
        } else {
          item.previousDocument.forEach((previousDocument, index) => {
            if (previousDocument.deleted) {
              return
            }
            // eslint-disable-next-line max-len
            const previousDocumentScope: FormScope = `${houseScope}.consignmentItem.${consignmentItemIndex}.previousDocument.${index}` as const
            const documentTypeRequirement = NA60Validator.validateConsignmentItemPreviousDocumentDocumentTypeRequirement(previousDocument)
            updatePathResult(`${previousDocumentScope}.documentType`, documentTypeRequirement)

            // Checks to avoid lock loop
            if (documentTypeRequirement !== 'NOT_ALLOWED') {
              const procedureRequirement = NA60Validator.validateConsignmentItemPreviousDocumentProcedureRequirement(previousDocument)
              updatePathResult(`${previousDocumentScope}.procedureType`, procedureRequirement)
            }

            let referenceNumberRequirement = NA61Validator.validateConsignmentItemPreviousDocumentRefNumberRequirement(previousDocument)

            if (referenceNumberRequirement === 'OPTIONAL') {
              referenceNumberRequirement = NA62Validator.validateConsignmentItemPreviousDocumentReferenceNumberRequirement(previousDocument)

              if (referenceNumberRequirement === 'NOT_ALLOWED' && hasText(previousDocument.referenceNumber)) {
                referenceNumberRequirement = 'OPTIONAL'
                addPathIssue(`${previousDocumentScope}.referenceNumber`, {
                  code: 'custom', path: `${previousDocumentScope}.referenceNumber`.split('.'), fatal: true, message: 'NA62 (Forbidden field filled)',
                })
              }
            }
            updatePathResult(`${previousDocumentScope}.referenceNumber`, referenceNumberRequirement)
          })
        }
      }
      if (country === 'RO') {
        if (item.sequenceNumber === 0) {
          if (item.dutyRateUnit === AmountUnit.AMOUNT) {
            updatePathResult(`${itemScope}.dutyAmount`, 'REQUIRED')
          } else {
            updatePathResult(`${itemScope}.dutyRate`, 'REQUIRED')
          }
          updatePathResult(`${itemScope}.price`, 'REQUIRED')
          updatePathResult(`${itemScope}.vatRate`, 'REQUIRED')
        }
      }

      updatePathResult(`${houseScope}.consignmentItem.${consignmentItemIndex}.packaging`, 'REQUIRED')
      item.packaging.forEach((packaging, packagingIndex) => {
        updatePathResult(`${houseScope}.consignmentItem.${consignmentItemIndex}.packaging.${packagingIndex}.typeOfPackages`, 'REQUIRED')
      })
      if (isItemDestinationEnabled && !hasUniqueItemDestinationCountries) {
        addPathIssue(`${itemScope}.countryOfDestination`, {
          code: 'custom', path: `${itemScope}.countryOfDestination`.split('.'), fatal: true, message: 'R0507',
        })
      }
      if (!hasUniqueItemReferenceUcr) {
        addPathIssue(`${itemScope}.referenceNumberUCR`, {
          code: 'custom', path: `${itemScope}.referenceNumberUCR`.split('.'), fatal: true, message: 'R0507',
        })
      }

      updatePathResult(`${itemScope}.commodityHarmonizedSystemSubHeadingCode`, 'REQUIRED')
      updatePathResult(`${itemScope}.commodityCombinedNomenclatureCode`, nomenclatureCodeRequirement)
      updatePathResult(`${itemScope}.referenceNumberUCR`, consignmentItemUcrRequirement)
      updatePathResult(`${itemScope}.countryOfDestination`, countryOfDestinationRequirement)
      updatePathResult(`${itemScope}.goodsMeasureNetMass`, goodsItemsNetMassRequirement)

      if (item.commodityCodeArchived) {
        addPathIssue(`${itemScope}.commodityHarmonizedSystemSubHeadingCode`, {
          code: 'custom', path: `${itemScope}.commodityHarmonizedSystemSubHeadingCode`.split('.'), fatal: true, message: 'ARCHIVED',
        })
      }

      const hasValidDeclarationType = R0601Validator.hasCorrectDeclarationTypeForProvidedDocument(item, houseConsignment, formData)
      if (!hasValidDeclarationType) {
        addPathIssue(`${itemScope}.declarationType`, {
          code: 'custom', path: `${itemScope}.declarationType`.split('.'), fatal: true, message: 'R0601',
        })
        addPathIssue('declarationType', {
          code: 'custom', path: ['declarationType'], fatal: true, message: 'R0601',
        })
      }

      // eslint-disable-next-line max-len
      const areDeclarationTypeAndCustomsOfficeOfDepartureCountryCorrect = R0909Validator.areDeclarationTypeAndCustomsOfficeOfDepartureCountryCorrect(formData, item)

      if (!areDeclarationTypeAndCustomsOfficeOfDepartureCountryCorrect) {
        if (!hasValidDeclarationType) {
          addPathIssue(`${itemScope}.declarationType`, {
            code: 'custom', path: `${itemScope}.declarationType`.split('.'), fatal: true, message: 'R0909',
          })
          addPathIssue('declarationType', {
            code: 'custom', path: ['declarationType'], fatal: true, message: 'R0909',
          })
        }
      }

      const isDeclarationTypeValid = R0911Validator.isDeclarationTypeValidForCustomsOfficeOfDepartureAndDestination(formData)
      if (!isDeclarationTypeValid) {
        addPathIssue(`${itemScope}.declarationType`, {
          code: 'custom', path: `${itemScope}.declarationType`.split('.'), fatal: true, message: 'R0911',
        })
        addPathIssue('declarationType', {
          code: 'custom', path: ['declarationType'], fatal: true, message: 'R0911',
        })
      }
      const hasRequiredPreviousDoc = R0020Validator.hasAtLeastOnePreviousDocumentValidForConsignmentItem(formData, item)
      if (!hasRequiredPreviousDoc) {
        addPathIssue(`${itemScope}.previousDocument`, {
          code: 'custom', path: `${itemScope}.previousDocument`.split('.'), fatal: true, message: 'R0020',
        })
      }

      const polishPreviousDocumentForConsignmentItemRequired = RP01Validator
        .validatePreviousDocumentRequirementForHouseAndItem(formData.houseConsignment) === 'REQUIRED'
        && formData.security === '0'
        && formData.declarationType === 'T1' ? 'REQUIRED' : 'OPTIONAL'

      const previousDocumentForConsignmentItemRequired = country === 'PL' ? polishPreviousDocumentForConsignmentItemRequired : C0035Validator.isPreviousDocumentForConsignmentItemRequired(formData, item)

      if (previousDocumentForConsignmentItemRequired) {
        updatePathResult(`${itemScope}.previousDocument`, previousDocumentForConsignmentItemRequired)
      }
      let consigneeOnConsignmentItemRequirement = IS_TRANSITIONAL_PERIOD ? B1820Validator.validateConsigneeOnConsignmentItemRequirement(formData, item) : 'OPTIONAL'
      if (consigneeOnConsignmentItemRequirement !== 'NOT_ALLOWED') {
        consigneeOnConsignmentItemRequirement = 'REQUIRED'
      }
      updatePathResult(`${itemScope}.consignee`, consigneeOnConsignmentItemRequirement)
      // ToDo: Check with UK and / or other countries
      // updatePathResult(`${itemScope}.consignee.identificationNumber`, consigneeOnConsignmentItemRequirement)
      updatePathResult(`${itemScope}.consignee.address`, consigneeOnConsignmentItemRequirement)

      if (consigneeOnConsignmentItemRequirement !== 'NOT_ALLOWED') {
        const itemConsigneeDetailsRequirement: RuleResult = 'REQUIRED' // C0250Validator.validateNameAndAddressRequirement(item.consignee?.identificationNumber)
        updatePathResult(`${itemScope}.consignee.name`, itemConsigneeDetailsRequirement)
        updatePathResult(`${itemScope}.consignee.address`, itemConsigneeDetailsRequirement)
        // @ts-ignore
        if (itemConsigneeDetailsRequirement !== 'NOT_ALLOWED') {
          updatePathResult(`${itemScope}.consignee.address.country`, itemConsigneeDetailsRequirement)
          updatePathResult(`${itemScope}.consignee.address.streetAndNumber`, itemConsigneeDetailsRequirement)
          updatePathResult(`${itemScope}.consignee.address.city`, itemConsigneeDetailsRequirement)
          const itemConsigneePostcodeRequirement = C0505Validator.validatePostcodeRequirement(item.consignee?.address?.country)
          updatePathResult(`${itemScope}.consignee.address.postcode`, itemConsigneePostcodeRequirement)
        }
        if (!hasUniqueItemConsignees) {
          addPathIssue(`${itemScope}.consignee`, {
            code: 'custom', path: `${itemScope}.consignee`.split('.'), fatal: true, message: 'B1877',
          })
        }
      }

      if (IS_TRANSITIONAL_PERIOD && country !== 'FI') {
        const itemTransportDocumentRequirement = B1896Validator.validateConsignmentItemTransportDocumentRequirement(formData, item)
        updatePathResult(`${itemScope}.transportDocument`, itemTransportDocumentRequirement)
      }

      // All item array types
      const {
        packaging,
        additionalReference,
        additionalInformation,
      } = item

      updatePathResult(`${itemScope}.additionalSupplyChainActor`, 'NOT_ALLOWED')
      updatePathResult(`${itemScope}.additionalReference`, 'NOT_ALLOWED') // TODO: E1301

      additionalReference.forEach((additionalRef, additionalReferenceIndex) => {
        if (additionalRef.deleted) {
          return
        }

        const additionalReferenceReferenceNumberRequirement = C0015Validator
          .validateConsignmentItemAdditionalReferenceRefNumberRequirement(additionalRef)
        updatePathResult(
          `${itemScope}.additionalReference.${additionalReferenceIndex}.referenceNumber`,
          additionalReferenceReferenceNumberRequirement,
        )
      })

      packaging.forEach((packages, index) => {
        const isPackagingValid = R0220Validator.isTypeOfPackagesValid(packages)

        if (!isPackagingValid) {
          addPathIssue(`${itemScope}.packaging.${index}.typeOfPackages`, {
            code: 'custom', path: `${itemScope}.packaging.${index}.typeOfPackages`.split('.'), fatal: true, message: 'R0220',
          })
        }
      })

      updatePathResult(`${itemScope}.additionalInformation`, 'NOT_ALLOWED') // TODO: E1301

      additionalInformation.forEach((additionalInfo, additionalInformationIndex) => {
        const isValidCode = R3061Validator.hasValidCodeForAdditionalInformation(additionalInfo)
        // TODO disable when transitional
        if (!isValidCode) {
          addPathIssue(`${itemScope}.additionalInformation.${additionalInformationIndex}.code`, {
            code: 'custom',
            path: `${itemScope}.additionalInformation.${additionalInformationIndex}.code`.split('.'),
            fatal: true,
            message: 'R3061',
          })
        }
      })
      packaging.forEach(({ deleted, typeOfPackages }, packageIndex) => {
        if (deleted) {
          return
        }

        const packageType = removePrefix(typeOfPackages, 'PACKAGE_TYPE_')

        const marksRequirement = C0060Validator.validatePackagingShippingMarksRequirement(packageType)
        updatePathResult(
          `${itemScope}.packaging.${packageIndex}.shippingMarks`,
          marksRequirement,
        )

        const numberOfPackagesRequirement = C0060Validator.validatePackagingNumberOfPackagesRequirement(packageType)
        updatePathResult(
          `${itemScope}.packaging.${packageIndex}.numberOfPackages`,
          numberOfPackagesRequirement,
        )
      })
    })
  })
  const transitOfficeRequirement = C0030Validator.validateOfficeOfTransitRequirement(formData)
  updatePathResult('transitCustomsOffices', transitOfficeRequirement)

  return updatedRules
}
export default invokeValidators
