import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import React, { useMemo, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { ArrayScope, ObjectScope } from 'types/DeclarationP5'
import { FileType } from '../form/schemas/fileSchemas'
import { isTransportOrSupportingDocumentFilesScope } from './TransitDocumentFileNames'
import { DeclarationForm } from '../form/schemas/declarationFormSchema'
import { BaseDocument } from '../form/schemas/documentSchemas'
import { excludeDeleted } from '../../common/utils/common-util'
import FileService from '../../../Declaration/services/file.service'

interface DocumentDropdownAreaProps {
  documentFilesScope: ArrayScope,
  documentScope: ObjectScope,
  maxSize?: number
}
const PERMITTED_MIME_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //  docx, doc
  'image/jpeg', //  jpg jpeg
  'application/vnd.etsi.asic-e+zip', //  asice, bdoc, ddoc, sce
  'image/gif', //  gif
  'application/rtf', //  rtf
  'application/x-rtf', //  rtf
  'text/richtext', //  rtf
  'text/csv', //  csv
  'image/png', //  png
  'image/tiff', //  tif, tiff
  'image/x-tiff', //  png
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //  xlsx
  'text/plain', //  txt
  'application/pdf', //  pdf
  'application/xml', //  xml
  'text/xml', //  xml
  'application/msword', //  doc
  'application/vnd.ms-excel', //  xls
  'application/x-excel', //  xls
  'application/x-msexcel', //  xls
  'application/vnd.oasis.opendocument.text', //  odt
  'application/vnd.oasis.opendocument.spreadsheet', //  ods
]

const LATVIAN_EXTENSIONS = [
  '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.png', '.jpeg', '.jpg', '.gif', '.tiff',
  '.tif', '.avi', '.flv', '.wmv', '.mov', '.mp4', '.mkv', '.ogg', '.xml', '.zip', '.rar',
  '.7z', '.ods', '.odt', '.txt', '.edoc', '.ms', '.asice', '.eml',
]

const UPLOAD_FILE_MAX_BYTES = 5242880 // 5*1024*1024
function DocumentDropdownArea(props: DocumentDropdownAreaProps) {
  const {
    documentFilesScope, documentScope, maxSize,
  } = props
  const { setValue, getValues } = useFormContext<DeclarationForm>()
  const { t } = useTranslation()
  const fileRef = useRef<HTMLInputElement>(null)

  const office = getValues('departureCustomsOffice')
  const country = useMemo(() => office.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2), [office])

  if (!isTransportOrSupportingDocumentFilesScope(documentFilesScope)) {
    throw Error('Unable to narrow, invalid scope')
  }

  const isFilenameUnique = (filename: string) => getValues(documentFilesScope)
    .filter((file: FileType) => !file.deleted && (file.fileName?.trim() === filename)).length === 0

  const generateNameForUploadedFile = (originalFilename: string) => {
    let filename = originalFilename.trim()
    let isUnique = isFilenameUnique(filename)
    if (isUnique) {
      return filename
    }

    let depth = 1
    while (!isUnique) {
      filename = FileService.addDuplicationNumberToName(originalFilename, depth)
      isUnique = isFilenameUnique(filename)
      if (!isUnique) {
        // eslint-disable-next-line no-plusplus
        depth++
      }
    }

    return filename
  }

  const addNewFiles = async (droppedFiles: FileList) => {
    const currentValues: FileType[] = getValues(documentFilesScope)
    const changedFiles = currentValues.length === 0 ? [] : [...currentValues.filter((file) => !(file.deleted && file.id === null))]
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < droppedFiles.length; i++) {
      const file = droppedFiles.item(i)

      if (!file) {
        toast.error('Error accessing file')
      } else if (file.size === 0) {
        toast.error(`File '${file.name}' size is invalid (0KB), please upload the correct file`)
      } else if (file.size > UPLOAD_FILE_MAX_BYTES) {
        toast.error(`File '${file.name}' size is too large. Maximum allowed size - ${UPLOAD_FILE_MAX_BYTES / 1024 / 1024} MiB`)
      } else {
        const fileName = generateNameForUploadedFile(file.name)
        if (fileName.length > 70) {
          toast.error(`File ${file.name} name too long`)
          return
        }
        if (country === 'EE') {
          if (!PERMITTED_MIME_TYPES.includes(file.type)) {
            toast.error(t('excel.invalidMimeType'))
            return
          }
        } else if (country === 'LV' && !LATVIAN_EXTENSIONS.some((extension) => file.name.toLowerCase().endsWith(extension))) {
          toast.error(t('excel.invalidMimeType'))
          return
        }
        // eslint-disable-next-line no-await-in-loop
        const fileBytes = await file.arrayBuffer()
        const fileBlob = fileBytes !== undefined ? new Blob([fileBytes], { type: file.type }) : null

        const newFile: FileType = {
          id: null,
          sequenceNumber: changedFiles.length,
          deleted: false,
          uuid: null,
          mimeType: file.type,
          url: null,
          fileName,
          fileBlob,
          file,
          documentId: (getValues(documentScope) as BaseDocument).id ?? null,
        }

        changedFiles.push(newFile)
        setValue(documentFilesScope, changedFiles)
      }
    }
  }

  const isDropAreaVisible = maxSize !== undefined
    ? getValues(documentFilesScope).filter(excludeDeleted).length < maxSize : true

  return (
    <div className="row mb-3" key={`documentBodyDropArea_${documentFilesScope}`}>
      <div className="col-12">
        {
          !isDropAreaVisible ? null : (
            <div
              role="presentation"
              className="dropdown-area bg-white"
              onClick={() => {
                if (fileRef.current) {
                  fileRef.current.click()
                }
              }}
              onDragOver={(event) => {
                event.currentTarget.classList.add('file-hover')
                event.preventDefault()
              }}
              onDragLeave={(event) => {
                event.currentTarget.classList.remove('file-hover')
              }}
              onDropCapture={(event) => {
                event.currentTarget.classList.remove('file-hover')

                addNewFiles(event.dataTransfer.files)

                event.preventDefault()
                event.stopPropagation()
              }}
            >
              <input
                className="d-none"
                type="file"
                ref={fileRef}
                onChange={(event) => {
                  if (event.target.files) {
                    addNewFiles(event.target.files)
                  }
                  event.preventDefault()
                  event.stopPropagation()
                }}
              />
              <i className="fal fa-cloud-upload me-2" />
              <span>
                {t('buttons.addFiles')}
              </span>
            </div>
          )
        }
      </div>
    </div>
  )
}
DocumentDropdownArea.defaultProps = {
  maxSize: undefined,
}

export default DocumentDropdownArea
