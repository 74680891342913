import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useContext, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BadRequestPayload } from 'types/Errors'
import { mapReasonToTranslationString } from 'helpers/errors.helper'
import i18n from 'i18n'
import useCurrencyExchangeRate from 'routes/phase5/common/hooks/useCurrencyExchangeRate'
import { isAxiosError } from '../../../../common/utils/api-util'
import { UseDeclarationFormReturn } from '../../../form'
import useSubmitActionApi from './api'
import { NctsError, TransitOperationContext } from '../../useTransitOperationContext'
import TransitApiConfig from '../../apiConfig'

const {
  queryKeys: {
    getTransitOperationById,
  },
} = TransitApiConfig.paths.transitOperation

function useSubmitAction(form: UseDeclarationFormReturn) {
  const {
    getValues,
  } = form

  const { submit } = useSubmitActionApi()
  const office = getValues('departureCustomsOffice')
  const country = useMemo(() => office.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2), [office])
  const { exchangeRate, exchangeRateLoading } = useCurrencyExchangeRate(country)
  const location = useLocation()
  const navigate = useNavigate()
  const { setNctsErrors } = useContext(TransitOperationContext)
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const submitTransitOperation = async () => {
    if (exchangeRateLoading) return
    const transitOperationId = getValues('id')
    if (transitOperationId === null) throw Error('Unable to submit without id')

    try {
      await submit.mutateAsync({ id: transitOperationId, currencyExchangeRate: exchangeRate })
      await queryClient.resetQueries({ queryKey: getTransitOperationById(transitOperationId) }).then(() => {
        toast.success(t('common.submit', { context: 'success' }))
      })
    } catch (error) {
      if (isAxiosError(error)) {
        const data = error.response?.data
        if (error.response?.request.status === 400) {
          if (data?.message === 'CONSTRAINT_VIOLATION') {
            data?.errors.forEach((errorItem: BadRequestPayload) => {
              const translatedString = mapReasonToTranslationString(errorItem)
              if (translatedString !== '' && translatedString !== null && translatedString !== undefined) {
                toast.error(`${t(`translations${i18n.language.toUpperCase()}:${errorItem.field}`)} ${translatedString}`)
              }
            })
            toast.error(t('common.submit', { context: 'error' }))
          }
          if (error.response?.data?.message.startsWith('Prepared request contains')) {
            const apiNctsErrors: NctsError[] = error.response?.data?.errors ?? []
            setNctsErrors(apiNctsErrors)
            navigate(`${location.pathname}#summary`)

            toast.error(t('common.submit', { context: 'error' }))
          }
          if (error.response?.data?.message.startsWith('At least one')) {
            toast.error(t('messages.requiredGoodsDocumentsNotUploaded', { context: '' }))
          }
        }
        if (error.response?.request.status === 503) {
          toast.error(
            t('common.submit', { context: 'service_unavailable' }),
            {
              autoClose: false,
              closeOnClick: false,
              draggable: false,
            },
          )
        }
      } else {
        toast.error(t('common.submit', { context: 'error' }))
      }
    }
  }

  return {
    submitTransitOperation,
  }
}

export default useSubmitAction
