import { excludeDeleted, nonNullConsignee, tradersEqual } from '../../../../common/utils/common-util'
import { DeclarationForm } from '../../schemas/declarationFormSchema'
import { Consignee } from '../../schemas/tradersSchema'

const B1877Validator = {
  hasMultipleUniqueConsigneeForConsignmentItem: (formData: DeclarationForm): boolean => {
    const allConsignmentItems = formData.houseConsignment
      .filter(excludeDeleted)
      .flatMap((house) => house.consignmentItem)
      .filter(excludeDeleted)

    if (allConsignmentItems.length === 1) return false

    if (allConsignmentItems
      .every((consignmentItem) => consignmentItem.consignee !== null && !nonNullConsignee(consignmentItem.consignee))) {
      return true
    }

    let previousConsignee: Consignee | null = null

    return allConsignmentItems
      .some((consignmentItem, index) => {
        if (index === 0) {
          previousConsignee = consignmentItem.consignee
          return false
        }
        if (!tradersEqual(previousConsignee, consignmentItem.consignee)) {
          return true
        }
        previousConsignee = consignmentItem.consignee
        return false
      })
  },

} as const

export default B1877Validator
