import { CarrierRequest, CarrierResponse } from '../../../common/models'
import { Carrier } from '../../form/schemas/tradersSchema'

export function toCarrierRequest(carrier: Carrier, transitOperationId: number): CarrierRequest {
  return {
    email: carrier.contactPerson?.email ?? undefined,
    eori: carrier.identificationNumber,
    carrierName: carrier.carrierName,
    id: carrier.id,
    name: carrier.contactPerson?.name ?? '',
    phone: carrier.contactPerson?.telephone ?? '',
    transitOperationId,
  }
}

export function parseCarrierResponse(response?: CarrierResponse | null): Carrier {
  return {
    id: response?.id ?? null,
    identificationNumber: response?.eori ?? '',
    carrierName: response?.carrierName ?? '',
    contactPerson: {
      deleted: false,
      email: response?.email ?? '',
      id: response?.id ?? null,
      name: response?.name ?? '',
      telephone: response?.phone ?? '',
    },
    deleted: false,
    target: 'CARRIER',
  }
}
