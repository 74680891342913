import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { BadRequestPayload } from 'types/Errors'
import { mapReasonToTranslationString } from 'helpers/errors.helper'
import i18n from 'i18n'
import TransitApiConfig from '../../apiConfig'
import useChangeActionApi from './api'
import { NctsError, TransitOperationContext } from '../../useTransitOperationContext'
import { UseDeclarationFormReturn } from '../../../form'
import { isAxiosError } from '../../../../common/utils/api-util'

const {
  queryKeys: {
    getTransitOperationById,
  },
} = TransitApiConfig.paths.transitOperation

function useChangeAction(form: UseDeclarationFormReturn) {
  const {
    getValues,
  } = form

  const { change } = useChangeActionApi()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { setNctsErrors } = useContext(TransitOperationContext)

  const changeTransitOperation = async () => {
    const transitOperationId = getValues('id')
    if (transitOperationId === null) throw Error('Unable to submit without id')

    try {
      await change.mutateAsync({ id: transitOperationId })
      await queryClient.resetQueries({ queryKey: getTransitOperationById(transitOperationId) }).then(() => {
        toast.success(t('common.submit', { context: 'success' }))
      })
    } catch (error) {
      if (isAxiosError(error)) {
        const data = error.response?.data
        if (error.response?.request.status === 400 && data?.message === 'CONSTRAINT_VIOLATION') {
          data?.errors.forEach((errorItem: BadRequestPayload) => {
            const translatedString = mapReasonToTranslationString(errorItem)
            if (translatedString !== '' && translatedString !== null && translatedString !== undefined) {
              toast.error(`${t(`translations${i18n.language.toUpperCase()}:${errorItem.field}`)} ${translatedString}`)
            }
          })
        }

        if (error.response?.data?.message.startsWith('Prepared request contains')) {
          const apiNctsErrors: NctsError[] = error.response?.data?.errors ?? []
          setNctsErrors(apiNctsErrors)
          navigate(`${location.pathname}#summary`)
        }
      }
      toast.error(t('common.submit', { context: 'error' }))
    }
  }

  return {
    changeTransitOperation,
  }
}

export default useChangeAction
