import { z } from 'zod'
import { AddressSchema, ContactPersonSchema } from './commonConsignmentSchemas'

export const TraderTargetEnum = z.enum(['CONSIGNEE', 'CONSIGNOR', 'CARRIER'])

const TraderSchema = z.object({
  id: z.number().nullable(),
  deleted: z.boolean().optional(),
  target: TraderTargetEnum,
  name: z.string(),
  identificationNumber: z.string().regex(/^([A-Z]{2}[0-9]{1,15})*$/),
  address: AddressSchema.nullable(),
  contactPerson: ContactPersonSchema.nullable(),
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ConsignorSchema = TraderSchema.superRefine((address, ctx) => {
})
export const ConsigneeSchema = TraderSchema.omit({
  contactPerson: true,
// eslint-disable-next-line @typescript-eslint/no-unused-vars
}).superRefine((arg, ctx) => {
})
export const CarrierSchema = TraderSchema
  .omit({ address: true, name: true })
  .extend({
    carrierName: z.string(),
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  .superRefine((arg, ctx) => {
  })

export type Consignor = z.infer<typeof ConsignorSchema>
export type Consignee = z.infer<typeof ConsigneeSchema>
export type Carrier = z.infer<typeof CarrierSchema>
